import React, { useEffect, useState } from 'react';
import { BannerMobile, ContainerBannerMobile } from './Style';
import { API_BASE_URL } from '../../../data/api/api';

export default function BannerTopoMobile() {
  const [bannerData, setBannerData] = useState(() => {
    // Tenta obter dados do localStorage no carregamento inicial
    const savedData = localStorage.getItem('bannerDataMobile');
    return savedData ? JSON.parse(savedData) : null;
  });

  useEffect(() => {
    // Função para buscar e armazenar dados no localStorage se necessário
    const fetchBannerData = () => {
      fetch(`${API_BASE_URL}/api/banner-home-topo`) // Rota para obter o banner mobile
        .then(response => response.json())
        .then(json => {
          if (json && json[0]) {
            setBannerData(json[0]); // Define o primeiro item do array
            localStorage.setItem('bannerDataMobile', JSON.stringify(json[0])); // Armazena no localStorage
          }
        })
        .catch(console.error);
    };

    // Verifica se já existem dados no localStorage; se não, busca os dados
    if (!bannerData) {
      fetchBannerData();
    }
  }, [bannerData]);

  if (!bannerData) {
    return <div>Loading...</div>;
  }

  const { banner_mobile } = bannerData;
  const imageUrl = `${API_BASE_URL}/storage/${banner_mobile}`;

  return (
    <ContainerBannerMobile>
      <BannerMobile>
        <img src={imageUrl} alt="Banner Mobile" />
      </BannerMobile>
    </ContainerBannerMobile>
  );
}
