import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../data/api/api';
import {
  CardContainer,
  CardDescription,
  CardGalery,
  CardImg,
  ContainerLoteButton,
  ContainerStatus
} from "../Style";
import "./styles.css";
import { ButtonSaibaMais } from "../../../components/Bottons/Bottons";
import seloVendido from "./img/selo-100-porcento-vendido.png";

export function CardEmpreend() {
  const [empreendimentosData, setEmpreendimentosData] = useState(null);

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/empreendimentos`)
      .then(response => response.json())
      .then(data => setEmpreendimentosData(data))
      .catch(error => console.error(error));
  }, []);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_BASE_URL}/storage/${imagePath.startsWith('/') ? imagePath.slice(1) : imagePath}`;
  };

  if (!empreendimentosData) {
    return <div>Loading...</div>;
  }

  return (
    <CardContainer>
      <h1>Nossos empreendimentos</h1>
      {empreendimentosData.map(empreendimento => {
        const {
          id,
          titulo,
          slug,
          descricao,
          vendido,
          quantidade_lotes,
          area_lote,
          status_construcao,
          capa_home
        } = empreendimento;

        const capaHomeUrl = getImageUrl(capa_home);

        // Determina se as informações de lote devem ser ocultadas
        const shouldHideLoteInfo = quantidade_lotes === 0 && area_lote === 0;

        return (
          <CardGalery key={id}>
            <CardImg>
              {capaHomeUrl && (
                <img
                  className="destaque"
                  src={capaHomeUrl}
                  alt="Imagem do empreendimento"
                />
              )}
              <ContainerStatus className={status_construcao === "Obra entregue" ? "green" : "orange"}>
                <p>{status_construcao}</p>
              </ContainerStatus>
            </CardImg>

            <CardDescription>
              {vendido === "Sim" && (
                <img
                  className="selo"
                  src={seloVendido}
                  alt="Empreendimento Completamente Vendido"
                />
              )}
              <h2>{titulo}</h2>
              <div dangerouslySetInnerHTML={{ __html: descricao }} />

              <ContainerLoteButton>
                {!shouldHideLoteInfo && (
                  <p>
                    <strong>{quantidade_lotes}</strong> Lotes com até <strong>{area_lote}</strong> m²
                  </p>
                )}
                <ButtonSaibaMais>
                  <a
                    href={`empreendimentos/${slug}`}
                    rel="noopener noreferrer"
                  >
                    Saiba mais
                  </a>
                </ButtonSaibaMais>
              </ContainerLoteButton>
            </CardDescription>
          </CardGalery>
        );
      })}
    </CardContainer>
  );
}
