import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../data/api/api';
import Title from "../BannerSecundario/img/TituloBanner.png";
import { BannerMobile, BannerTitle, ContainerBannerMobile } from "./Style";

export default function BannerSecundarioMobile() {
  const [bannerData, setBannerData] = useState(() => {
    // Tenta obter dados do localStorage no carregamento inicial
    const savedData = localStorage.getItem('bannerDataSecundarioMobile');
    return savedData ? JSON.parse(savedData) : null;
  });
  const [isLoading, setIsLoading] = useState(!bannerData); // Define loading com base na presença de dados

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/banner-home-conteudo`);
        if (!response.ok) {
          throw new Error('Erro ao buscar os dados do banner');
        }
        const data = await response.json();
        if (data.length > 0) {
          setBannerData(data[0]); // Captura o primeiro item do array
          localStorage.setItem('bannerDataSecundarioMobile', JSON.stringify(data[0])); // Armazena no localStorage
        } else {
          console.error('Nenhum dado de banner encontrado');
        }
      } catch (error) {
        console.error('Erro ao buscar dados do banner:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Verifica se já existem dados no localStorage; se não, busca os dados
    if (!bannerData) {
      fetchBannerData();
    } else {
      setIsLoading(false); // Se os dados já estão disponíveis, não está mais carregando
    }
  }, [bannerData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!bannerData || !bannerData.banner_mobile) {
    return <div>Erro ao carregar o banner mobile.</div>;
  }

  const { banner_mobile } = bannerData;

  return (
    <ContainerBannerMobile>
      <BannerTitle>
        <img src={Title} alt="Título do Banner" />
      </BannerTitle>
      <BannerMobile>
        <img src={`${API_BASE_URL}/storage/${banner_mobile}`} alt="Banner Mobile" />
      </BannerMobile>
    </ContainerBannerMobile>
  );
}
