import React, { useEffect, useState } from 'react';
import { ContainerBanner } from './Style';
import BtnExplorar from './ButtonExplorar/BtnExplorar';
import { API_BASE_URL } from '../../../data/api/api';

export default function BannerPrincipal() {
  const [bannerData, setBannerData] = useState(() => {
    // Verifica se os dados já estão no localStorage
    const savedData = localStorage.getItem('bannerData');
    return savedData ? JSON.parse(savedData) : null;
  });

  useEffect(() => {
    // Faz a requisição apenas se os dados não estiverem no localStorage
    if (!bannerData) {
      fetch(`${API_BASE_URL}/api/banner-home-topo`)
        .then(response => response.json())
        .then(data => {
          if (data && data[0]) {
            setBannerData(data[0]); // Captura o primeiro banner da lista
            localStorage.setItem('bannerData', JSON.stringify(data[0])); // Armazena no localStorage
          }
        })
        .catch(console.error);
    }
  }, [bannerData]);

  if (!bannerData) {
    return <div>Loading...</div>;
  }

  const desktopImageUrl = `${API_BASE_URL}/storage/${bannerData.banner_desktop}`;
  console.log("Desktop Image URL:", desktopImageUrl);

  return (
    <ContainerBanner>
      <BtnExplorar />
      <ContainerBanner>
        <img src={desktopImageUrl} alt={bannerData.titulo} />
      </ContainerBanner>
    </ContainerBanner>
  );
}
