import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../data/api/api";
import {
  CardContainer,
  CardDescription,
  CardGalery,
  ContainerStatus,
} from "../Style";
import Arrow from "./img/arrow.svg";
import "./styles.css"; // importa o arquivo CSS

export function CardEmpreend() {
  const [empreendimentos, setEmpreendimentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmpreendimentos = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/empreendimentos`);
        if (!response.ok) {
          throw new Error("Erro ao buscar os empreendimentos");
        }
        const data = await response.json();

        // Filtrando os empreendimentos que estão destacados
        const empreendimentosDestacados = data.filter(
          (empreendimento) => empreendimento.destaque === "Sim" // Ajuste conforme necessário
        );

        setEmpreendimentos(empreendimentosDestacados); // Atualizando o estado com apenas os empreendimentos em destaque
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchEmpreendimentos();
  }, []);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_BASE_URL}/storage/${imagePath.startsWith('/') ? imagePath.slice(1) : imagePath}`;
  };

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>Erro: {error}</div>;

  return (
    <CardContainer>
      {empreendimentos.length === 0 ? (
        <div>Nenhum empreendimento em destaque no momento.</div>
      ) : (
        empreendimentos.map((empreendimento) => {
          const capaHomeUrl = getImageUrl(empreendimento.capa_home);

          return (
            <CardGalery key={empreendimento.id}>
              {/* Exibindo a imagem da capa do empreendimento */}
              {capaHomeUrl && (
                <img
                  className="destaque"
                  src={capaHomeUrl}
                  alt="Imagem do empreendimento"
                />
              )}

              {/* Status da construção */}
              <ContainerStatus
                className={
                  empreendimento.status_construcao === "Obra entregue"
                    ? "green"
                    : "orange"
                }
              >
                <p>{empreendimento.status_construcao}</p>
              </ContainerStatus>

              <CardDescription>
                {/* Título e Tipo do Empreendimento */}
                <h3>{empreendimento.titulo}</h3>
                <h4>{empreendimento.tipo_empreendimento}</h4>

                {/* Link para o empreendimento */}
                <a
                  href={`/empreendimentos/${empreendimento.slug}`}  // Usando o slug para o link
                  rel="noopener noreferrer"
                >
                  Conhecer
                  <img src={Arrow} alt="Arrow" />
                </a>
              </CardDescription>
            </CardGalery>
          );
        })
      )}
    </CardContainer>
  );
}
