import React, { useState, useEffect } from "react";
import { BlocoNumbers, ContainerNumeros, StyleNumbers } from "./Style";

export default function NumerosBloco({ numerosIniciais }) {
  const [lotes, setLotes] = useState(0);
  const [casas, setCasas] = useState(0);
  const [sonhos, setSonhos] = useState(0);

  useEffect(() => {
    // Verifique se numerosIniciais existe antes de começar a contagem
    if (!numerosIniciais) return;

    const targetLotes = numerosIniciais.lotes_vendidos || 0; // Acesse a propriedade correta
    const targetCasa = numerosIniciais.casas_construidas || 0; // Acesse a propriedade correta
    const targetSonhos = numerosIniciais.sonhos_realizados || 0; // Acesse a propriedade correta

    const interval = setInterval(() => {
      setLotes((prevLotes) => {
        if (prevLotes >= targetLotes) {
          clearInterval(interval);
          return targetLotes; // Garante que o valor final seja definido
        }
        const diff = targetLotes - prevLotes;
        const increment = Math.ceil(diff / 10); // Ajusta a lógica de incremento
        return prevLotes + Math.max(increment, 50); // Garante que o incremento seja pelo menos 1
      });

      setCasas((prevCasas) => {
        if (prevCasas >= targetCasa) {
          clearInterval(interval);
          return targetCasa;
        }
        const diff = targetCasa - prevCasas;
        const increment = Math.ceil(diff / 10);
        return prevCasas + Math.max(increment, 20);
      });

      setSonhos((prevSonhos) => {
        if (prevSonhos >= targetSonhos) {
          clearInterval(interval);
          return targetSonhos;
        }
        const diff = targetSonhos - prevSonhos;
        const increment = Math.ceil(diff / 10);
        return prevSonhos + Math.max(increment, 50);
      });
    });

    return () => clearInterval(interval); // Limpa o intervalo ao desmontar
  }, [numerosIniciais]); // Executa o efeito quando numerosIniciais mudar

  return (
    <ContainerNumeros>
      <BlocoNumbers>
        <StyleNumbers>
          <h1>+{lotes}</h1>
          <strong> <p>lotes vendidos</p></strong>
        </StyleNumbers>
        <StyleNumbers>
          <h1>+{casas}</h1>
          <strong> <p>casas construidas</p></strong>
        </StyleNumbers>
        <StyleNumbers>
          <h1>+{sonhos}</h1>
          <strong><p>sonhos realizados</p></strong>
        </StyleNumbers>
      </BlocoNumbers>
    </ContainerNumeros>
  );
}
