import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer';
import BannerTopo from './BannerTopo/BannerTopo';
import SobreContent from './SectionSobre/SobreContent';
import BannerFooter from './BannerFooter/BannerFooter';
import BannerSobreMobile from './BannerTopo/BannerSobreMobile';
import ButtonContact from '../../components/ButtonContact/ButtonContact';
import api from '../../services/api';
import banners from "../../data/api/banners";
import { API_BASE_URL } from "../../data/api/api";

export default function Sobre() {
  const [bannerPrincipal, setBannerPrincipal] = useState(() => {
    // Tenta obter o banner principal do localStorage
    return localStorage.getItem('bannerPrincipal') || null;
  });

  const [bannerSecundario, setBannerSecundario] = useState(() => {
    // Tenta obter o banner secundário do localStorage
    return localStorage.getItem('bannerSecundario') || null;
  });

  const placeholders = banners.sobre;

  useEffect(() => {
    const obterBanners = async () => {
      try {
        // Fetch para o banner principal
        const { data: bannersTopo } = await api.get(`${API_BASE_URL}/api/banner-sobre-topo`);
        const bannerTopo = bannersTopo[0];
        const desktopImageUrlTopo = `${API_BASE_URL}/storage/${bannerTopo.banner_desktop}`;
        setBannerPrincipal(desktopImageUrlTopo || placeholders.banner_desktop);
        // Armazena o banner principal no localStorage
        localStorage.setItem('bannerPrincipal', desktopImageUrlTopo || placeholders.banner_desktop);

        // Fetch para o banner secundário (rodapé)
        const { data: bannersRodape } = await api.get(`${API_BASE_URL}/api/banner-sobre-rodape`);
        const bannerRodape = bannersRodape[0];
        const mobileImageUrlRodape = `${API_BASE_URL}/storage/${bannerRodape.banner_mobile}`;
        setBannerSecundario(mobileImageUrlRodape || placeholders.banner_mobile);
        // Armazena o banner secundário no localStorage
        localStorage.setItem('bannerSecundario', mobileImageUrlRodape || placeholders.banner_mobile);

      } catch (error) {
        console.error("Erro ao buscar banners:", error);
        setBannerPrincipal(placeholders.banner_desktop);
        setBannerSecundario(placeholders.banner_mobile);
      }
    };

    // Se os banners não estiverem disponíveis, busca os dados da API
    if (!bannerPrincipal || !bannerSecundario) {
      obterBanners();
    }
  }, [placeholders, bannerPrincipal, bannerSecundario]);

  return (
    <>
      <Navbar />
      {bannerPrincipal ? <BannerTopo banner={bannerPrincipal} /> : <div>Loading Banner Principal...</div>}
      {bannerSecundario ? <BannerSobreMobile banner={bannerSecundario} /> : <div>Loading Banner Secundário...</div>}
      <SobreContent />
      {bannerSecundario ? <BannerFooter banner={bannerSecundario} /> : <div>Loading Banner Footer...</div>}
      <ButtonContact />
      <Footer />
    </>
  );
}
