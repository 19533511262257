import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import FooterFaleConosco from '../../components/footerFaleConosco/Footer'
import ButtonContact from '../../components/ButtonContact/ButtonContact'

export default function FaleConosco() {
  return (
    <>
      <Navbar />
      <ButtonContact />
      <FooterFaleConosco />
    </>
  )
}
