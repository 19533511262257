import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import ButtonContact from "../../components/ButtonContact/ButtonContact";
import { API_BASE_URL } from '../../../src/data/api/api';
import {
  ContainerDescription,
  ContainerHeader,
  ContainerIcons,
  ContainerPlanta,
  ContainerSobre,
  SectionDescription,
  SectionGalery,
  SobreStyleText
} from './Style';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function EmpreendimentoInterna() {
  const { id } = useParams(); // Captura o slug do empreendimento
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [empreendimento, setEmpreendimento] = useState(null);
  const [infraestruturas, setInfraestruturas] = useState([]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const defaultProps = { zoom: 15 };

  // Função de fetch geral
  const fetchData = async (endpoint, callback, errorMsg) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/${endpoint}`);
      const data = await response.json();
      if (response.ok) {
        callback(data);
      } else {
        setError(errorMsg);
      }
    } catch (error) {
      setError(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Busca os dados do empreendimento pelo slug
    fetchData(`empreendimentos/${id}`, (data) => {
      const { latitude, longitude, ...empreendimentoData } = data;
      setEmpreendimento(empreendimentoData);
      setLatitude(latitude || 0);
      setLongitude(longitude || 0);
    }, "Failed to fetch empreendimento data.");

    // Busca os dados de infraestruturas
    fetchData("infraestruturas", setInfraestruturas, "Failed to fetch infraestruturas data.");
  }, [id]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Função para renderizar os ícones de infraestrutura
  const renderInfrastructureIcons = () =>
    infraestruturas.map((infra) => (
      <div className="styleIcons" key={infra.id}>
        <img
          src={`${API_BASE_URL}/storage/${infra.imagem}`}
          alt={`Infraestrutura: ${infra.titulo}`}
          style={{ width: '100px', height: 'auto', margin: '10px' }}
        />
        <p>{infra.titulo}</p>
      </div>
    ));

  // Função para renderizar a galeria de imagens
  const renderGalleryImages = () =>
    empreendimento.galeria_empreendimento?.map((image, index) => (
      <img
        key={index}
        src={`${API_BASE_URL}/storage/${image}`}
        alt={`Gallery ${index + 1}`}
        style={{ width: '100%', height: 'auto', margin: '10px 0' }}
      />
    ));

  return (
    <>
      <Navbar />
      <ContainerHeader>
        {empreendimento.logo_empreendimento && (
          <img src={`${API_BASE_URL}/storage/${empreendimento.logo_empreendimento}`} alt="Logo do Empreendimento" />
        )}
      </ContainerHeader>

      <SectionDescription>
        <ContainerPlanta>
          {empreendimento.planta_baixa && (
            <img src={`${API_BASE_URL}/storage/${empreendimento.planta_baixa}`} alt="Planta Baixa" />
          )}
        </ContainerPlanta>

        <ContainerDescription>
          <ContainerSobre>
            <SobreStyleText>
              <h2>{empreendimento.titulo}</h2>
              <div dangerouslySetInnerHTML={{ __html: empreendimento.descricao }} />
              <p className="numeros">
                <strong>{empreendimento.quantidade_lotes}</strong> lotes com até{" "}
                <strong>{empreendimento.area_lote}</strong> m²
              </p>
            </SobreStyleText>
          </ContainerSobre>
          <ContainerIcons>{renderInfrastructureIcons()}</ContainerIcons>
        </ContainerDescription>
      </SectionDescription>

      <SectionGalery>{renderGalleryImages()}</SectionGalery>

      <div style={{ height: '60vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
          defaultCenter={{ lat: latitude, lng: longitude }}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={latitude} lng={longitude} text={empreendimento.titulo} />
        </GoogleMapReact>
      </div>

      <ButtonContact />
      <Footer />
    </>
  );
}
