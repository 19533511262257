import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../data/api/api';
import { ContainerBanner } from './Style';

export default function BannerFooter() {
  const [desktopImage, setDesktopImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/banner-sobre-rodape`);
        if (!response.ok) {
          throw new Error('Erro ao buscar os dados do banner');
        }
        const data = await response.json();

        if (data.length > 0) {
          const banner = data[0]; // Assumindo que sempre pegamos o primeiro banner
          setDesktopImage(banner.banner_desktop);
          setMobileImage(banner.banner_mobile);
        } else {
          console.error('Nenhum dado de banner encontrado');
        }
      } catch (error) {
        console.error('Erro ao buscar dados do banner:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBannerData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!desktopImage || !mobileImage) {
    return <div>Erro ao carregar os banners.</div>;
  }

  return (
    <ContainerBanner>
      <div className="BannerDesktop">
        <img
          src={`${API_BASE_URL}/storage/${desktopImage}`}
          alt="Banner Desktop"
        />
      </div>

      <div className="BannerMobile">
        <img
          src={`${API_BASE_URL}/storage/${mobileImage}`}
          alt="Banner Mobile"
        />
      </div>
    </ContainerBanner>
  );
}
