import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import data from "../../../data/pageSobre/sobre";
import NumerosBloco from "../../../components/Numeros/NumerosBloco";
import numerosPlaceholder from "../../../data/api/numeros";
import controleQualidade from "../../../data/pageSobre/controleQualidade";
import {
  ContainerNumerero,
  ContainerNumererosQualidade,
  ContainerQualidade,
  ContainerSobre
} from "./Style";
import { API_BASE_URL } from "../../../data/api/api"; // Certifique-se de importar a URL base

export default function SobreContent() {
  const [numerosIniciais, setNumerosIniciais] = useState(() => {
    // Tenta obter os números iniciais do localStorage
    const savedNumeros = localStorage.getItem('numerosIniciais');
    return savedNumeros ? JSON.parse(savedNumeros) : null;
  });

  useEffect(() => {
    async function obterNumerosIniciais() {
      try {
        const { data: numerosApi } = await api.get(`${API_BASE_URL}/api/nossos-numeros`);

        // Verifique se os dados estão no formato correto (array com pelo menos um objeto)
        if (Array.isArray(numerosApi) && numerosApi.length > 0) {
          setNumerosIniciais(numerosApi[0]); // Apenas o primeiro objeto do array
          // Armazena os números no localStorage
          localStorage.setItem('numerosIniciais', JSON.stringify(numerosApi[0]));
        } else {
          console.error("Formato inesperado dos dados de números.");
          setNumerosIniciais(numerosPlaceholder);
        }
      } catch (error) {
        console.error("Erro ao buscar números iniciais:", error);
        setNumerosIniciais(numerosPlaceholder);
      }
    }

    // Se números iniciais não estiverem disponíveis, busca os dados da API
    if (!numerosIniciais) {
      obterNumerosIniciais();
    }
  }, [numerosIniciais]); // O array de dependências inclui numerosIniciais

  return (
    <>
      <ContainerSobre>
        {data.map((item) => (
          <div key={item.id}><br />
            <h1>{item.title}</h1><br />
            <p>{item.descricao}</p><br /> <strong></strong>
          </div>
        ))}
      </ContainerSobre>
      <ContainerNumererosQualidade>
        <ContainerNumerero>
          {numerosIniciais && <NumerosBloco numerosIniciais={numerosIniciais} />}
        </ContainerNumerero>
        <ContainerQualidade>
          {controleQualidade.map((item) => (
            <div key={item.id}>
              <h1>{item.title}</h1><br />
              <p>{item.descricao}</p>
            </div>
          ))}
        </ContainerQualidade>
      </ContainerNumererosQualidade>
    </>
  );
}
