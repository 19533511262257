import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Input, InputArea, Popup, Radio } from "./Style";
import { API_BASE_URL } from "../../../../src/data/api/api"; // Import da URL base da API

export default function Formulario() {
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    mensagem: '',
    subscription: false,
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    const newValue = name === 'subscription' ? checked : value;
    setState((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!state.name || !state.email || !state.phone || !state.mensagem) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    try {
      setIsLoading(true);
      const data = {
        nome: state.name,
        email: state.email,
        telefone: state.phone.replace(/[()-\s]/g, ""),
        mensagem: state.mensagem,
        quero_receber_conteudo: state.subscription ? 1 : 0
      };

      await axios.post(`${API_BASE_URL}/api/fale-conosco`, data, {
        headers: { 'Content-Type': 'application/json' }
      });

      setState({ name: '', email: '', phone: '', mensagem: '', subscription: false });
      setStatusMessage("Obrigado, recebemos o seu contato. Retornaremos o mais breve possível.");
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
      setError(error.response?.data?.message || "Ocorreu um erro ao enviar o formulário.");
    } finally {
      setIsLoading(false);
    }
  };

  const resetMessages = () => {
    setStatusMessage("");
    setError("");
  };

  return (
    <Form onSubmit={handleSubmit}>
      {statusMessage && (
        <Popup className="show" role="alert" aria-live="assertive">
          <p>SUCESSO!</p>
          {statusMessage}
          <button onClick={resetMessages}>Fechar</button>
        </Popup>
      )}
      {error && (
        <Popup className="show" role="alert" aria-live="assertive">
          <p>X</p>
          {error}
          <button onClick={resetMessages}>Fechar</button>
        </Popup>
      )}

      <Input
        type="text"
        id="name"
        name="name"
        placeholder="Qual o seu nome"
        value={state.name}
        onChange={handleChange}
        disabled={isLoading}
      />

      <Input
        type="email"
        id="email"
        name="email"
        placeholder="Digite seu e-mail"
        value={state.email}
        onChange={handleChange}
        disabled={isLoading}
      />

      <Input
        type="tel"
        id="phone"
        name="phone"
        placeholder="Digite seu melhor telefone"
        value={state.phone}
        onChange={handleChange}
        disabled={isLoading}
      />

      <InputArea
        id="mensagem"
        name="mensagem"
        placeholder="Quer deixar aqui uma mensagem?"
        value={state.mensagem}
        onChange={handleChange}
        disabled={isLoading}
      />

      <Radio>
        <label>
          <input
            type="checkbox"
            name="subscription"
            checked={state.subscription}
            onChange={handleChange}
            disabled={isLoading}
          />
          <strong> Quero receber conteúdo.</strong>
        </label>
      </Radio>

      <Button type="submit" disabled={isLoading}>
        {isLoading ? 'Enviando...' : 'Enviar'}
      </Button>
    </Form>
  );
}
