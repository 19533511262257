import styled from "styled-components";

// Exportação do ContainerSobre
export const ContainerSobre = styled.div`
  padding: 10px;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 550px) {
    padding: 5px;
    h1 {
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      color: #0a0a0a;
    }
  }
`;

// Exportação do ContainerNumererosQualidade
export const ContainerNumererosQualidade = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;
  }
`;

// Exportação do ContainerNumerero
export const ContainerNumerero = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Estilos já existentes
export const ContainerNumeros = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 1200px;
  justify-content: flex-start;

  @media (max-width: 550px) {
    margin-left: 3px;
    max-width: 350px;

    h2 {
      font-size: 20px;
    }
  }
`;

export const BlocoDesc = styled.div`
  display: flex;
  width: 50%;
  padding: 5px;
`;

export const BlocoNumbers = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;

  @media (max-width: 550px) {
    width: 350px;
  }
`;

export const StyleNumbers = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: auto;
  width: 180px;
  gap: 10px;

  @media (max-width: 550px) {
    width: 150px;
  }

  h1 {
    margin-bottom: -10px;
    color: #118faa;
    font-size: 32px;
    font-weight: 700;
  }
`;
