import React, { useEffect, useState } from 'react';
import { IconSocial } from './Style';
import axios from 'axios';
import { API_BASE_URL } from "../../data/api/api";

export default function RedesSociais() {
  const [redesSociais, setRedesSociais] = useState([]);

  useEffect(() => {
    async function obterRedesSociais() {
      try {
        const { data } = await axios.get(`${API_BASE_URL}/api/redes-sociais`);
        setRedesSociais(data);
      } catch (error) {
        console.error("Erro ao buscar redes sociais:", error);
      }
    }

    obterRedesSociais();
  }, []);

  return (
    <IconSocial>
      {redesSociais.map((rede) => (
        <a key={rede.id} href={rede.link} target="_blank" rel="noopener noreferrer">
          <img src={`${API_BASE_URL}/storage/${rede.imagem}`} alt={rede.nome} />
        </a>
      ))}
    </IconSocial>
  );
}
