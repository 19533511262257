import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../data/api/api';
import { BannerMobile, ContainerBannerMobile } from './Style';

export default function BannerSobreMobile() {
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/banner-sobre-topo`); // Acesse o endpoint correto
        if (!response.ok) {
          throw new Error('Erro ao buscar os dados do banner');
        }
        const data = await response.json();

        setBannerData(data[0]); // Acesso aos dados do banner
      } catch (error) {
        console.error('Erro ao buscar banner:', error);
      }
    };

    fetchBannerData();
  }, []);

  if (!bannerData) {
    return <div>Loading...</div>; // Exibe Loading enquanto os dados não são carregados
  }

  const { banner_mobile, titulo } = bannerData; // Acesso aos atributos do banner

  return (
    <ContainerBannerMobile>
      <BannerMobile>
        <img
          src={`${API_BASE_URL}/storage/${banner_mobile}`} // Certifique-se de que a URL esteja correta
          alt={titulo || 'Banner Mobile'} // Use um título se disponível
        />
      </BannerMobile>
    </ContainerBannerMobile>
  );
}
