import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../data/api/api';

const TesteDeApi = () => {
    const [empreendimentos, setEmpreendimentos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchEmpreendimentos = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/empreendimentos`); // Certifique-se de usar o endpoint correto.
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setEmpreendimentos(data);
            } catch (error) {
                setError(error.message);
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEmpreendimentos();
    }, []);

    if (loading) {
        return <div>Carregando...</div>;
    }

    if (error) {
        return <div>Erro ao carregar empreendimentos: {error}</div>;
    }

    return (
        <div>
            <h1>Teste de API</h1>
            <ul>
                {empreendimentos.map(empreendimento => (
                    <li key={empreendimento.id}>
                        <h2>{empreendimento.titulo}</h2>
                        <p><strong>Descrição:</strong> {empreendimento.descricao}</p>
                        <p><strong>Tipo:</strong> {empreendimento.tipo_empreendimento}</p>
                        <p><strong>Status da Construção:</strong> {empreendimento.status_construcao}</p>
                        <p><strong>Quantidade de Lotes:</strong> {empreendimento.quantidade_lotes}</p>
                        <p><strong>Área do Lote:</strong> {empreendimento.area_lote} m²</p>
                        <p><strong>Vendido:</strong> {empreendimento.vendido ? 'Sim' : 'Não'}</p>
                        <p><strong>Destaque:</strong> {empreendimento.destaque ? 'Sim' : 'Não'}</p>
                        {/* Adicionando a capa_home */}
                        {empreendimento.capa_home && (
                            <div>
                                <h3>Capa do Empreendimento:</h3>
                                <img
                                    src={`http://127.0.0.1:8000/storage/${empreendimento.capa_home}`} // Verifique se o caminho da imagem está correto.
                                    alt="Capa do Empreendimento"
                                    style={{ width: '200px', height: 'auto' }} // Ajuste a largura conforme necessário.
                                />
                            </div>
                        )}
                        {/* Exibindo a galeria de imagens */}
                        {empreendimento.galeria_empreendimento && empreendimento.galeria_empreendimento.length > 0 && (
                            <div>
                                <h3>Galeria:</h3>
                                <ul>
                                    {empreendimento.galeria_empreendimento.map((imagem, index) => (
                                        <li key={index}>
                                            <img
                                                src={`http://127.0.0.1:8000/storage/${imagem}`} // Verifique se o caminho da imagem está correto.
                                                alt={`Imagem ${index + 1}`}
                                                style={{ width: '100px', height: '100px' }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TesteDeApi;
