import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../../data/api/api';
import { ContainerBanner } from './Style';

export default function BannerTopo() {
  const [bannerData, setBannerData] = useState(null);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/banner-sobre-topo`); // Acesse o endpoint correto
        if (!response.ok) {
          throw new Error('Erro ao buscar os dados do banner');
        }
        const data = await response.json();

        // Acesso aos atributos conforme a estrutura que você forneceu
        setBannerData(data[0]); // Aqui você pode ajustar dependendo de como os dados são retornados
      } catch (error) {
        console.error('Erro ao buscar banner:', error);
      }
    };

    fetchBannerData();
  }, []);

  if (!bannerData) {
    return <div>Loading...</div>; // Exibe Loading enquanto os dados não são carregados
  }

  const { banner_desktop, titulo } = bannerData; // Acesso aos atributos do banner

  return (
    <ContainerBanner>
      <img
        src={`${API_BASE_URL}/storage/${banner_desktop}`} // Certifique-se de que a URL esteja correta
        alt={titulo || 'Banner Sobre'} // Use um título se disponível
      />
    </ContainerBanner>
  );
}
