import styled from "styled-components";

export const ContainerEmpreend = styled.section`
  display: flex;
  margin: auto;
  max-width: 1248px;
  margin-bottom: 50px;
  width: 100%;
  scroll-behavior: smooth;
  html {
  scroll-behavior: smooth;
  text-align: justify;
}

`;

export const ContainerVermais = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  max-width: 1440px;
  height: 90px;
`;

export const ContainerStatus = styled.div`
  display: flex;
  position: absolute;
  margin: auto;
  justify-content: center;
  width: 118px;
  height: 29px;
  gap: 8px;
  padding: 6px, 12px, 6px, 12px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  p {
    font-size: 12px;
    margin: auto;
    font-weight: 600;
    line-height: 18.8px;
  }
`;

export const StatusStyle = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  max-width: 1440px;
  height: 90px;
  
`;

export const CardGalery = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative; /* Define a posição como relativa para o selo */
  min-width: 399px;
  max-width: 399px;
  min-height: 299px;
  max-height: 299px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #ebebeb;

  @media (max-width: 500px) {
    max-width: 370px; 
    min-width: 300px;  

  }

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }

  .image-container:hover img {
    transform: scale(1.1);
  }

  img.destaque {
    width: auto;
    height: 100%;
    object-fit: cover;
  }

  img.selo {
    width: 60px;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10; /* Garante que o selo fica na frente do card */
  }

  @media (max-width: 1024px) {
    width: 700px;
    padding: 5px;   
    margin-bottom: 24px;
    max-width: 800px;

    .image-container {
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.2s ease-in-out;
    }
  }

  @media (max-width: 500px) {
    padding: 5px;
    margin-bottom: 24px;
    width: 340px;
    margin-left: 10px;
    
    .image-container {
      max-width: 340px;
    }
  }
`;



export const CardContainer = styled.div`
  display: flex;
  margin: auto;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  padding: 5px;
/*   background-color: #d60c0ce3; */
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    margin: auto;
    flex-direction: column;
 /*    background-color: #27c52f; */
  }
`;

export const CardDescription = styled.div`
 @media (max-width: 500px) {
  width: 100%;
  width: 320px;
  max-height: 102px;
  margin-top: 180px;
  color: #e80000;
  a{
    color: #e80000;
  }
  p{
    color: #e80000;
  }

 }


  display: flex;
  margin-top: 174px;
  position: absolute;
  width: 380px;
  height: 107px;
  max-width: 400px;
  background: linear-gradient(to bottom, #aeaeae48 50%, #131a1fcb 100%);
  backdrop-filter: blur(20px);
  flex-direction: column;
  color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px;
  h3 {
    font-weight: 700;
  
  }
  h4 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 30px;
  }
  a {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 8px;
    color: #fff;
    img {
      width: 30px;
      height: 20px;
    }
    &:hover{
      font-weight: 700;
      font-size: 15px;          
    }
  }
`;