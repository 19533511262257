import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import NumerosBloco from "../../../components/Numeros/NumerosBloco";
import BigNumeros from "./BigNumeros";
import { BlocoDesc, BlocoNumbers, ContainerNumeros, ContainerVideoEnumeros } from "./Style";
import Youtube from "./Youtube";
import { API_BASE_URL } from "../../../data/api/api";

export default function Numeros() {
  const [numerosIniciais, setNumerosIniciais] = useState(() => {
    // Tenta obter dados do localStorage no carregamento inicial
    const savedData = localStorage.getItem('numerosIniciais');
    return savedData ? JSON.parse(savedData) : null;
  });

  useEffect(() => {
    const obterNumerosIniciais = async () => {
      try {
        const { data } = await api.get(`${API_BASE_URL}/api/nossos-numeros`);

        // Verifique se os dados estão no formato correto (array com pelo menos um objeto)
        if (Array.isArray(data) && data.length > 0) {
          setNumerosIniciais(data[0]); // Apenas o primeiro objeto do array
          // Armazena os dados no localStorage
          localStorage.setItem('numerosIniciais', JSON.stringify(data[0]));
        } else {
          console.error("Formato inesperado dos dados de números.");
        }
      } catch (error) {
        console.error("Erro ao buscar números iniciais:", error);
      }
    };

    // Se não houver dados no localStorage, busca os números iniciais
    if (!numerosIniciais) {
      obterNumerosIniciais();
    }
  }, [numerosIniciais]);

  return (
    <ContainerVideoEnumeros>
      <Youtube />

      <ContainerNumeros>
        <BlocoDesc>
          {numerosIniciais && <BigNumeros detalhes={numerosIniciais} />}
        </BlocoDesc>
        <BlocoNumbers>
          {numerosIniciais && <NumerosBloco numerosIniciais={numerosIniciais} />}
        </BlocoNumbers>
      </ContainerNumeros>
    </ContainerVideoEnumeros>
  );
}
