import React, { useEffect, useState } from 'react';
import { YoutubeItem } from './Style';
import { API_BASE_URL } from '../../../data/api/api'; // Importa a constante API_BASE_URL

export default function Youtube() {
  const [videos, setVideos] = useState(() => {
    // Tenta obter dados do localStorage no carregamento inicial
    const savedVideos = localStorage.getItem('videos');
    return savedVideos ? JSON.parse(savedVideos) : [];
  });
  const [loading, setLoading] = useState(!videos.length); // Indica que o loading está ativo se não houver vídeos no estado
  const [error, setError] = useState(null); // Estado para controle de erros

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/videos`); // Usa a constante API_BASE_URL
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVideos(data); // Atualiza o estado com os vídeos obtidos
        // Armazena os vídeos no localStorage
        localStorage.setItem('videos', JSON.stringify(data));
      } catch (error) {
        setError(error.message); // Atualiza o estado de erro em caso de falha
      } finally {
        setLoading(false); // Indica que o carregamento foi concluído
      }
    };

    // Se não houver vídeos no estado, busca os vídeos da API
    if (!videos.length) {
      fetchVideos(); // Chama a função para buscar os vídeos
    } else {
      setLoading(false); // Se vídeos já estão no estado, termina o loading
    }
  }, [videos]); // O array de dependências inclui videos para evitar chamadas desnecessárias

  if (loading) {
    return <div>Carregando...</div>; // Exibe mensagem de loading
  }

  if (error) {
    return <div>Erro: {error}</div>; // Exibe mensagem de erro
  }

  return (
    <>
      {videos.map(video => (
        <YoutubeItem key={video.id}>
          <div dangerouslySetInnerHTML={{ __html: video.embed }} /> {/* Renderiza o embed do vídeo */}
        </YoutubeItem>
      ))}
    </>
  );
}
